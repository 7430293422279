<template>
  <div class="select-order">
    <div class="row">
      <el-input style="max-width: 60%; padding-left: 20px; margin-right: 30px;" v-model="orderNum" :placeholder="$t('Enter the order no.')"></el-input>
      <el-button @click="searchOrder">{{ $t('Search') }}</el-button>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">{{ $t('Select') }}</th>
          <th scope="col">{{ $t('Order No.') }}</th>
          <th scope="col">{{ $t('Date') }}</th>
          <th scope="col">{{ $t('Order Status') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders">
          <th scope="row"><el-radio v-model="selectedOrder" :label="order.id"></el-radio></th>
          <td>{{ order.reference_id }}</td>
          <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
          <td>{{ $t(order.order_status) }}</td>
        </tr>
      </tbody>
    </table>
    <div class="dialog-footer">
      <el-button type="primary" @click="saveOrder">{{ $t('Save') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Order from '@/lib/order';

export default {
  name: 'SelectOrder',
  data(){
    return {
      orderNum: '',
      orders: [],
      selectedOrder: '',
    };
  },
  methods:{
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    saveOrder(){
      const selectedOrder = this.selectedOrder;
      const order = this.orders.find(function(order){
        return selectedOrder === order.id;
      });
      console.log(order);
      this.$emit('orderSelected', order);
    },
    async searchOrder(){
      try{
        const loginInfo = Common.getLoginInfo();
        const searchFields = {
          'reference_id': this.orderNum,
        };
        const orders = await Order.searchOrder(this.apiUrl, searchFields, loginInfo);
        this.orders = orders;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.select-order{
  .table{
    margin-top: 30px;
  }
  .dialog-footer{
    text-align: right;
    margin-top: 30px;
  }
  .el-radio__label{
    display: none;
  }
}
</style>
